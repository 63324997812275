@media (min-width: 1224px) {
    .media-query-width {
        width: 12%;
        top: -500px;
    }
}

@media (max-width: 1223px) {
    .media-query-width {
        width: 24%;

    }
}