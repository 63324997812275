.coming-soon {
	position: relative;
	width: 100%;
	background-color: inherit !important;
}

.loader {
	background: linear-gradient(-135deg, var(--color-top-right), var(--color-bottom-left));
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity 600ms;
    font-family: 'Roboto Mono' monospace !important;
	margin: 0px !important;
}

.loader .wrapper{
	height: 90%;
}

.loader .wrapper .header {
	height: 25%;
}

.loader .wrapper .top-header {
	height: 65%;
	/* media query */
	font-size: 8rem;
}

.loader .wrapper .bottom-header {
	height: 35%;
	/* media query */
	font-size: 2.8rem;
}

.loader .wrapper .bottom-header .left {
	text-align: right;
}
.loader .wrapper .bottom-header .right {
	text-align: left;
	padding-left: 2rem;
}
.loader .wrapper .footer {
	margin-top:5%;
	height: 5%;
	/* media query */
	font-size: 5rem;
}

.loader .wrapper .right {
	text-align: left;
	padding-left: 2rem;
}

.loader .wrapper .left {
	text-align: right;
}

.flip-clock-col {
	height: 15vw;
}

.flip-clock {
	height: 100%;
	margin: auto;
	width: 80%;
	/* media query */
	--fcc-digit-block-width: 15rem !important; 
	--fcc-digit-block-height: 18rem !important; 
	--fcc-digit-font-size: 10rem !important;
	--fcc-label-font-size: 4rem !important;
	--fcc-separator-size: 0.8rem !important;
}


.flip-clock > div {
	height: 100%;
	margin: 0px;
	padding: 0px;
	margin-bottom: 0px !important;
}

.flip-clock > div:nth-child(2n+1){
	width: 22%;
}

/* even child divs are spacers */
.flip-clock > div:nth-child(2n){
	width: 4%;
}

@media (max-width: 1799px) and (min-width: 1200px){
	.loader .wrapper .top-header {
		/* media query */
		font-size: 4rem !important;
	}
	
	.loader .wrapper .bottom-header {
		/* media query */
		font-size: 1.6rem !important;
	}
	
	.loader .wrapper .footer {
		/* media query */
		font-size: 3rem !important;
	}
	
	.flip-clock {
		/* media query */
		--fcc-digit-block-width: 10rem !important; 
		--fcc-digit-block-height: 10rem !important;  
		--fcc-label-font-size: 2rem !important;
		--fcc-digit-font-size: 6rem !important;
		--fcc-separator-size: 0.6rem !important;
	}
	
}


@media (max-width: 1199px) and (min-width: 992px){
	.loader .wrapper .top-header {
		/* media query */
		font-size: 5rem !important;
	}
	
	.loader .wrapper .bottom-header {
		/* media query */
		font-size: 2.5rem !important;
	}
	
	.loader .wrapper .footer {
		/* media query */
		font-size: 3rem !important;
		margin:auto;
		align-items: center;
		height: 15% !important;
	}
	
	.flip-clock {
		/* media query */
		--fcc-digit-block-width: 10rem !important; 
		--fcc-digit-block-height: 10rem !important;  
		--fcc-label-font-size: 2rem !important;
		--fcc-digit-font-size: 6rem !important;
		--fcc-separator-size: 0.5rem !important;
	}

	.loader .wrapper .bottom-header .left {
		text-align: center !important;
	}
	.loader .wrapper .bottom-header .right {
		text-align: center;
		font-size: 2rem !important;
	}

	.loader .wrapper .footer .left {
		text-align: center !important;
	}
	.loader .wrapper .footer .right {
		text-align: center !important;
	}
	
}

@media (max-width: 991px) and (min-width: 768px){
	.loader .wrapper .top-header {
		/* media query */
		font-size: 4rem !important;
	}
	
	.loader .wrapper .bottom-header {
		/* media query */
		font-size: 2rem !important;
	}
	
	.loader .wrapper .footer {
		/* media query */
		font-size: 2.5rem !important;
		margin:auto;
		align-items: center;
		height: 15% !important;
	}
	.flip-clock {
		/* media query */
		--fcc-digit-block-width: 15rem !important; 
		--fcc-digit-block-height: 8rem !important;  
		--fcc-label-font-size: 1.5rem !important;
		--fcc-digit-font-size: 6rem !important;
		--fcc-separator-size: 0.6rem !important;
	}

	.loader .wrapper .bottom-header .left {
		text-align: center !important;
	}
	.loader .wrapper .bottom-header .right {
		text-align: center;
		font-size: 1.5rem !important;
	}

	.loader .wrapper .footer .left {
		text-align: center !important;
	}
	.loader .wrapper .footer .right {
		text-align: center !important;
	}
	
}

@media (max-width: 767px) and (min-width:600px){
	.flip-clock-row, .flip-clock-col, .flip-clock{
		height: 10rem !important;
		width: 95% !important;
		margin:auto !important;
	}

	.loader .wrapper .top-header {
		/* media query */
		font-size: 2.5rem !important;
	}
	
	.loader .wrapper .bottom-header {
		/* media query */
		font-size: 1.3rem !important;
	}
	
	.loader .wrapper .footer {
		/* media query */
		font-size: 2.5rem !important;
		margin:auto;
		align-items: center;
		height: 15% !important;
		padding-left: 0px  !important;
	}
	
	.flip-clock {
		/* media query */
		--fcc-digit-block-width: 30rem !important; 
		--fcc-digit-block-height: 10rem !important;  
		--fcc-label-font-size: 1.5rem !important;
		--fcc-digit-font-size: 6rem !important;
		--fcc-separator-size: 0.3rem !important;
	}

	.loader .wrapper .bottom-header .left {
		text-align: center !important;
	}
	.loader .wrapper .bottom-header .right {
		text-align: center;
	}

	.loader .wrapper .footer .left {
		text-align: center !important;
	}
	.loader .wrapper .footer .right {
		text-align: center !important;
		padding-left: 0px !important;
	}
	
}

@media (max-width: 599px) {
	.flip-clock-row, .flip-clock-col, .flip-clock{
		height: 8rem !important;
		width: 95% !important;
		margin:auto !important;
	}

	.loader .wrapper .top-header {
		/* media query */
		font-size: 2rem !important;
	}
	
	.loader .wrapper .footer {
		/* media query */
		font-size: 1.5rem !important;
		margin:auto;
		align-items: center;
		height: 10% !important;
	}
	
	.flip-clock {
		/* media query */
		--fcc-digit-block-width: 30rem !important; 
		--fcc-digit-block-height: 8rem !important;  
		--fcc-label-font-size: 1rem !important;
		--fcc-digit-font-size: 4rem !important;
		--fcc-separator-size: 0.3rem !important;
	}

	.loader .wrapper .bottom-header .left {
		text-align: center !important;
		font-size: 1.1rem !important;
	}
	.loader .wrapper .bottom-header .right {
		text-align: center;
		font-size: 0.9rem !important;
		padding-left: 0px !important;
	}

	.loader .wrapper .footer .left {
		text-align: center !important;
	}
	.loader .wrapper .footer .right {
		text-align: center !important;
		padding-left: 0px !important;
	}
	
}


/* @media (max-width: 1199px) and (min-width: 800px){
	.flip-clock {
		height: 5vh;
		margin-right: 2.5%;
		margin-left: 2.5%;
		width: 95%;
		--fcc-digit-font-size: 250% !important;
		--fcc-digit-block-width: 100% !important;
		--fcc-digit-block-height: 70% !important;
	}
	
	.flip-clock > div:nth-child(2n+1){
		width: 22% !important;
		height: 100% !important;
	}
	.flip-clock > div:nth-child(2n){
		width: 3% !important;
		height: 100% !important;
	}
} */

/* @media (max-width: 799px) {
	.flip-clock {
		height: 5vh;
		margin-right: 2.5%;
		margin-left: 2.5%;
		width: 95%;
		--fcc-digit-font-size: 250% !important;
		--fcc-digit-block-width: 100% !important;
		--fcc-digit-block-height: 70% !important;
	}
	
	.flip-clock > div:nth-child(2n+1){
		width: 22% !important;
		height: 100% !important;
	}
	.flip-clock > div:nth-child(2n){
		width: 3% !important;
		height: 100% !important;
	}
} */

